// import * as THREE from 'three';
global.THREE = require('three/build/three.module');
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

const THREE_PATH = `https://unpkg.com/three@0.${THREE.REVISION}.x`
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

import pm25 from '../modules/pm25';

import {TWEEN} from "three/examples/jsm/libs/tween.module.min";
global.TW = TWEEN;

export default class main3d {
   
   constructor() {
      
      this.crossFadeControls = [];
      
      this.allActions = [];
      
      this.cameraFolder = null;
      this.animFolder = null;
      this.animCtrls = [];
      this.morphFolder = null;
      this.morphCtrls = [];
      this.skeletonHelpers = [];
      this.gridHelper = null;
      this.axesHelper = null;
      
      this.panelSettings = null;
      this.numAnimations = null;
      
      this.clock = null;
      this.mixer = null;
      this.mixers = [];
      
      // this.setContent = this.setContent.bind(this);
      this.addContent = this.addContent.bind(this);
      // this.loadAnims = this.loadAnims.bind(this);
      // this.setClips = this.setClips.bind(this);
      this.animate = this.animate.bind(this);
      this.onWindowResize = this.onWindowResize.bind(this);
      
      this.changeSong = this.changeSong.bind(this);
      this.generateScreenshot = this.generateScreenshot.bind(this);
      
      this.mesh;
      this.points = [];

      this.boneNodeOffsets = [
         2,20,59,30,18,44,37,32,19,58,14,24,25,34,38,43,26,1,35,13,41,3,29,40,8,33,39,50,12,7,15,27,10,23,21,4,45,42,49,11,48,28,31,56,60,47,53,57,16,5,17,52,51,54,46,36,0,22,9,6,55,
         51,14,9,30,43,0,28,6,37,2,34,31,44,46,24,25,55,35,5,40,23,58,33,27,22,42,56,26,39,52,12,19,18,16,36,38,50,21,59,32,53,15,47,57,10,3,13,7,4,8,45,48,11,
         6,10,7,30,28,12,20,24,23,16,15,3,29,5,9,4,25,18,11,13,27,8,19,14,2,1,17,26,0,21,22,
         24,15,42,11,38,12,26,39,25,43,20,17,13,23,27,29,50,21,41,48,40,33,19,10,47,34,22,35,30,28,16,37,32,46,49,44,45,14,18,36,31,
         2,20,59,30,18,44,37,32,19,58,14,24,25,34,38,43,26,1,35,13,41,3,29,40,8,33,39,50,12,7,15,27,10,23,21,4,45,42,49,11,48,28,31,56,60,47,53,57,16,5,17,52,51,54,46,36,0,22,9,6,55,
         51,14,9,30,43,0,28,6,37,2,34,31,44,46,24,25,55,35,5,40,23,58,33,27,22,42,56,26,39,52,12,19,18,16,36,38,50,21,59,32,53,15,47,57,10,3,13,7,4,8,45,48,11,
         2,20,59,30,18,44,37,32,19,58,14,24,25,34,38,43,26,1,35,13,41,3,29,40,8,33,39,50,12,7,15,27,10,23,21,4,45,42,49,11,48,28,31,56,60,47,53,57,16,5,17,52,51,54,46,36,0,22,9,6,55,
      ]

      this.colorNodeOrder = [
         2,20,59,30,18,44,37,32,19,58,14,24,25,34,38,43,26,1,35,13,41,3,29,40,8,33,39,50,12,7,15,27,10,23,21,4,45,42,49,11,48,28,31,56,60,47,53,57,16,5,17,52,51,54,46,36,0,22,9,6,55,
         51,14,9,30,43,0,28,6,37,2,34,31,44,46,24,25,55,35,5,40,23,58,33,27,22,42,56,26,39,52,12,19,18,16,36,38,50,21,59,32,53,15,47,57,10,3,13,7,4,8,45,48,11,
         2,20,59,30,18,44,37,32,19,58,14,24,25,34,38,43,26,1,35,13,41,3,29,40,8,33,39,50,12,7,15,27,10,23,21,4,45,42,49,11,48,28,31,56,60,47,53,57,16,5,17,52,51,54,46,36,0,22,9,6,55,
         51,14,9,30,43,0,28,6,37,2,34,31,44,46,24,25,55,35,5,40,23,58,33,27,22,42,56,26,39,52,12,19,18,16,36,38,50,21,59,32,53,15,47,57,10,3,13,7,4,8,45,48,11,
         6,10,7,30,28,12,20,24,23,16,15,3,29,5,9,4,25,18,11,13,27,8,19,14,2,1,17,26,0,21,22,
         24,15,42,11,38,12,26,39,25,43,20,17,13,23,27,29,50,21,41,48,40,33,19,10,47,34,22,35,30,28,16,37,32,46,49,44,45,14,18,36,31,
         2,20,59,30,18,44,37,32,19,58,14,24,25,34,38,43,26,1,35,13,41,3,29,40,8,33,39,50,12,7,15,27,10,23,21,4,45,42,49,11,48,28,31,56,60,47,53,57,16,5,17,52,51,54,46,36,0,22,9,6,55,
         51,14,9,30,43,0,28,6,37,2,34,31,44,46,24,25,55,35,5,40,23,58,33,27,22,42,56,26,39,52,12,19,18,16,36,38,50,21,59,32,53,15,47,57,10,3,13,7,4,8,45,48,11,
         2,20,59,30,18,44,37,32,19,58,14,24,25,34,38,43,26,1,35,13,41,3,29,40,8,33,39,50,12,7,15,27,10,23,21,4,45,42,49,11,48,28,31,56,60,47,53,57,16,5,17,52,51,54,46,36,0,22,9,6,55,
         51,14,9,30,43,0,28,6,37,2,34,31,44,46,24,25,55,35,5,40,23,58,33,27,22,42,56,26,39,52,12,19,18,16,36,38,50,21,59,32,53,15,47,57,10,3,13,7,4,8,45,48,11,
         6,10,7,30,28,12,20,24,23,16,15,3,29,5,9,4,25,18,11,13,27,8,19,14,2,1,17,26,0,21,22,
         24,15,42,11,38,12,26,39,25,43,20,17,13,23,27,29,50,21,41,48,40,33,19,10,47,34,22,35,30,28,16,37,32,46,49,44,45,14,18,36,31,
      ];
      
      this.state = {
         currentModel: null,
         models: [],
         background: false,
         playbackSpeed: 0.5,
         actionStates: [],
         wireframe: false,
         skeleton: false,
         grid: false,
      };
      
      
      this.bloomParams = {
         bloomThreshold: 0.0,
         bloomStrength: 0.2,
         bloomRadius: 20
      };
      
      
      this.init();
   }
   
   /*
   const action = _3d.state.actionStates[1];
   _3d.mixer.stopAllAction();
   action.reset();
   action.fadeIn(0.5);
   action.play();
   ;
   */
   
   init() {
      
      const _this = this;
      
      let scene, renderer, camera, stats;
      let model, skeleton;
      
      const container = document.getElementById( 'main3d' );
      _this.clock = new THREE.Clock();
      
      scene = new THREE.Scene();
      scene.background = new THREE.Color( 0x000000 );
      scene.fog = new THREE.Fog( 0x000000, 3.1, 100 );
      scene.rotation.y = Math.random()*0.2 - 0.1;
      scene.rotation.x = Math.random()*0.4 - 0.2;
      scene.rotation.z = Math.random()*0.4 - 0.2;
      
      const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444 );
      hemiLight.position.set( 0, 20, 40 );
      // hemiLight.intensity = 0.8;
      hemiLight.intensity = 0.4;
      _this.hemiLight = hemiLight;
      scene.add( hemiLight );
      
      const ambientLight = new THREE.AmbientLight(0xffffaa, 0.05)
      ambientLight.position.set( 0, 20, 0 );
      _this.ambientLight = ambientLight;
      scene.add( ambientLight );
      
      const dirLight = new THREE.DirectionalLight( 0x666666 );
      dirLight.position.set( 0, 20, 10 );
      dirLight.castShadow = true;
      dirLight.shadow.camera.top = 2;
      dirLight.shadow.camera.bottom = - 2;
      dirLight.shadow.camera.left = - 2;
      dirLight.shadow.camera.right = 2;
      dirLight.shadow.camera.near = 0.1;
      dirLight.shadow.camera.far = 40;
      dirLight.intensity = 2.0;
      _this.dirLight = dirLight;
      scene.add( dirLight );
      
      
      // pm25
      this.pm25 = new pm25();
      this.pm25.init(scene);
      
      
      this.content = new THREE.Object3D();
      this.content.position.z =  -150;
      scene.add(this.content);
      
      // models + loader
      const manager = new THREE.LoadingManager();      
      const loader = new GLTFLoader(manager)
      .setMeshoptDecoder( MeshoptDecoder );
      
      var modelsCount = 0;
      
      const blobURLs = [];
      
      // load all models
      let anims = [
         'dance',
         'drawing',
         'falling',
         'looking2',
         'situps',
         'sleep',
         'surf',
      ];
      
      var loadCounter = 0;

      manager.onLoad = function ( ) {
         // console.log( 'Loading complete!');
         // console.log('loadCounter', loadCounter);
         loadCounter++;
         if(loadCounter==anims.length) {
            console.log('begin');
            _this.begin();
            
            console.log('animate');
            _this.animate();
         } else {
            loader.load(`/dist/models/${anims[loadCounter]}.glb`, (gltf) => {
               const scene = gltf.scene || gltf.scenes[0];
               const clips = gltf.animations || [];
               console.log(`model #${loadCounter} is: ${anims[loadCounter]}`);
               _this.addContent(scene, clips);
            });
         }
      };

      manager.onError = function ( url ) {
         console.log( 'There was an error loading ' + url );
      };

      loader.load(`/dist/models/${anims[0]}.glb`, (gltf) => {
         const scene = gltf.scene || gltf.scenes[0];
         const clips = gltf.animations || [];
         console.log(`model #${loadCounter} is: ${anims[loadCounter]}`);
         _this.addContent(scene, clips);
      });
      
      renderer = new THREE.WebGLRenderer({
         antialias: true,
         preserveDrawingBuffer: true,
         powerPreference: 'low-power',
      });

      if(window.safari) {
         renderer.setPixelRatio( 1 );
      } else {
         renderer.setPixelRatio( window.devicePixelRatio );
      }
      renderer.setSize( window.innerWidth, window.innerHeight );
      renderer.outputEncoding = THREE.sRGBEncoding;
      renderer.shadowMap.enabled = true;
      container.appendChild( renderer.domElement );
      
      // camera
      camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 1000 );
      camera.position.set( Math.random()*30 - 15, Math.random()*10 - 5, 30 );
      
      // controls
      const controls = new OrbitControls( camera, renderer.domElement );
      controls.enableDamping = true;
      controls.dampingFactor = 0.01;
      // controls.enablePan = false;
      // controls.enableZoom = false;
      controls.rotateSpeed = 0.6;
      controls.zoomSpeed = 0.3;
      controls.target.set( 0, 5.5, 0.5);
      controls.maxDistance = 75;
      controls.minDistance = 10;
      controls.update();

      if(window.location.href.indexOf('embed')>=0){
         controls.autoRotate = true;
      }

      
      // stats
      stats = new Stats();
      container.appendChild( stats.dom );
      stats.dom.style.top = 'auto';
      stats.dom.style.bottom = '0';
      $(stats.dom).addClass('stats');
      
      // composer
      const renderScene = new RenderPass( scene, camera );
      
      const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
      _this.bloomPass = bloomPass;
      bloomPass.threshold = _this.bloomParams.bloomThreshold;
      bloomPass.strength = _this.bloomParams.bloomStrength;
      bloomPass.radius = _this.bloomParams.bloomRadius;
      this.bloomPass = bloomPass;
      
      const composer = new EffectComposer( renderer );
      composer.addPass( renderScene );
      composer.addPass( bloomPass );
      
      window.addEventListener( 'resize', this.onWindowResize );
      
      
      _this.scene = scene;
      _this.renderer = renderer;
      _this.camera = camera;
      _this.stats = stats;
      _this.model = model;
      _this.skeleton = skeleton;
      _this.controls = controls;
      _this.composer = composer;
      
      window.scene = scene;
      
      window.main = this;
      
   }
   
   addContent ( object, clips ) {
      const _this = this;
      if(object) {
         const box = new THREE.Box3().setFromObject(object);
         const size = box.getSize(new THREE.Vector3()).length();
         const center = box.getCenter(new THREE.Vector3());
         
         // object.position.x += (object.position.x - center.x);
         // object.position.y += (object.position.y - center.y);
         // object.position.z += (object.position.z - center.z);
         // object.position.y += 0.5;
         
         let fillMaterial = new THREE.MeshPhongMaterial({
            color: 0x777777,
            opacity: 1.0,
            transparent: true,
            // flatShading: false,
            // fog: false,
         });
         
         // object.visible = false;
         object.visible = false;
         _this.scene.add(object);
         
         _this.content.add(object);
         
         _this.state.models.push(object);

         

         // Colouring !

         var nodeCounter = 0;
         var boneNodeCounter = 0;

         // assign colorIDs
         object.traverse((node) => {
            node.frustumCulled = false;

            if (node.isMesh) {
               node.colorID = nodeCounter;
               nodeCounter++;
            }
            if (node.isBone) {
               node.boneID = boneNodeCounter;
               boneNodeCounter++;
            }
         });

         let air = window.dataStream ? window.dataStream.air + 2.0 : -5.0;
         
         const weatherRed = 0.5 + ((air / 40) * 0.2);
         const weatherGreen = Math.max(0.3, Math.min(1, 0.5 + ((air-2)/-20)*.5));
         const weatherBlue = 0.5 + ((air / -40) * .8);
         const weatherOffsetCalc = window.dataStream ? Math.floor(window.dataStream.water * 2 + Math.abs(window.dataStream.air)*2) : 10.0;
            
         object.traverse((node) => {
            if (node.isMesh) {
               let weatherBasedColor = new THREE.Color( weatherRed, weatherGreen, weatherBlue );
               let offsetID = _this.colorNodeOrder[node.colorID + weatherOffsetCalc];
               let hueRotate = ((0.6 * (offsetID/60)) - (50*0.05));
               let saturate = (0.4 + (1.2 * (offsetID/60))) * (0.6 + (50*0.4));
               let NEWCOLOR = weatherBasedColor.offsetHSL(hueRotate, saturate, -0.02);
               
               let coloredMaterial = new THREE.MeshLambertMaterial({
                  color: NEWCOLOR,
                  opacity: 1.0,
                  transparent: false,
                  flatShading: false,
               });
               // node.castShadow = true;
               // node.receiveShadow = true;
               node.material = coloredMaterial;
            } // ismesh
         }); // traverse node

         if(clips) {
            let newMixer = new THREE.AnimationMixer(object);
            // newMixer.timeScale = 0.4;
            _this.mixers.push(newMixer);
            
            clips.forEach((clip) => {
               const action = newMixer.clipAction(clip); //.reset().play();
               _this.state.actionStates.push(action);
               action.play();
            });
         }
      }
   }
   
   begin() {
      // start the show
      
      const _this = this;
      
      if(!window.seeID){
         window.loadBg();

         setTimeout(function(){
            $('body').addClass('show-ui');
         }, 2000);
      }

      $('body').addClass('loaded');
      $('#main3d').addClass('in');

      
      if(window.dataStream) {
         _this.changeSong(window.dataStream.listens.int);
      }
      
      _this.points.forEach(p => {
         let gathering = new TW.Tween(p.uniforms.sand.action)
         .to({ value: 1 }, 3500)
         .easing(TW.Easing.Quadratic.Out)
         // .delay(800)
         .start();
      });
      
      let zoomOut = new TW.Tween(_this.content.position)
      .to({ 
         z: 0
      }, 6000)
      .easing(TW.Easing.Circular.Out)
      .start();
      
      let sceneRotation = new TW.Tween(_this.scene.rotation)
      .to({ 
         z: 0,
         x: 0,
         y: 0
      }, 9000)
      .easing(TW.Easing.Quadratic.Out)
      .start();
      
   }
   
   onWindowResize() {
      
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      
      this.renderer.setSize( window.innerWidth, window.innerHeight );
      this.composer.setSize( window.innerWidth, window.innerHeight );
      
   }
   
   changeSong(songValue) {
      console.log('New song, int:', songValue);
      
      var _this = this;

      if(songValue==128) songValue = 127.9;
      
      if(_this.state.models) {
         const selected = Math.floor(((songValue / 128) * _this.state.models.length));
         _this.state.currentModel = selected;
         for(let i=0; i<_this.state.models.length; i++) {
            _this.state.models[i].visible = i==selected;
         }
         
         var eatsValue = window.dataStream ? window.dataStream.eats.int : 0;
         
         if(_this.state.models[_this.state.currentModel]){
            _this.state.models[_this.state.currentModel].traverse((node) => {
               if (node.isBone) {
                  let sc = 1 - (_this.boneNodeOffsets[node.boneID + eatsValue] * .0015);
                  node.scale.set(sc, sc, sc);
               } // isBone
            }); // traverse node
         }
      }
   }
   
   changeLights(trainValue, surpriseValue) {
      const _this = this;

      const TRAINPRC = trainValue / 128;
      const SURPRISEPRC = surpriseValue / 128;
      
      let newSupriseValue = -10 + (40 * SURPRISEPRC);
      let newTrainValue = -15 + (40 * TRAINPRC);
      new TW.Tween(_this.dirLight.position)
      .to({ 
         x: newSupriseValue,
         y: newTrainValue,
       }, 220)
      .easing(TW.Easing.Quadratic.Out)
      .start();
   }

   changeColouring(surpriseValue) {
      // console.log('New surprise:', surpriseValue);
      
      var _this = this;
      
      if(_this.state.models.length>0 && _this.state.currentModel!==null) {
         
         let air = window.dataStream ? window.dataStream.air + 2.0 : -5.0;
         
         if(_this.state.models[_this.state.currentModel]){
            
            const weatherRed = 0.5 + ((air / 40) * 0.2);
            const weatherGreen = Math.max(0.3, Math.min(1, 0.5 + ((air-2)/-20)*.5));
            const weatherBlue = 0.5 + ((air / -40) * .8);
            const weatherOffsetCalc = window.dataStream ? Math.floor(window.dataStream.water * 2 + Math.abs(window.dataStream.air)*2) : 10.0;
            
            // console.log(weatherRed, weatherGreen, weatherBlue);
            
            const SURPRISEPRC = surpriseValue / 128;
            
            _this.state.models[_this.state.currentModel].traverse((node) => {
               if (node.isMesh) {
                  
                  let weatherBasedColor = new THREE.Color( weatherRed, weatherGreen, weatherBlue );
                  let offsetID = _this.colorNodeOrder[node.colorID + weatherOffsetCalc];
                  
                  let hueRotate = ((0.6 * (offsetID/60)) - (SURPRISEPRC*0.05));
                  let saturate = (0.4 + (1.2 * (offsetID/60))) * (0.6 + (SURPRISEPRC*0.4));
                  let NEWCOLOR = weatherBasedColor.offsetHSL(hueRotate, saturate, -0.02);
                  
                  let clrTween = new TW.Tween(node.material.color)
                  .to(NEWCOLOR, 1100)
                  .easing(TW.Easing.Quadratic.Out)
                  .onUpdate(function(){
                     node.material.needsUpdate = true;
                  })
                  .start();
               } // ismesh
            }); // traverse node
         }
      }
   }
   
   async generateScreenshot(){
      var originalWidth = window.innerWidth;
      var originalHeight = window.innerHeight;
      
      this.renderer.setSize( 1024, 1024 );
      this.composer.setSize( 1024, 1024 );
      this.camera.aspect = 1024 / 1024;
      this.camera.updateProjectionMatrix();
      this.composer.render( this.scene, this.camera );
      var screenshot = this.renderer.domElement.toDataURL();
      
      this.renderer.setSize( originalWidth, originalHeight );
      this.composer.setSize( originalWidth, originalHeight );
      this.composer.render( this.scene, this.camera );
      
      this.camera.aspect = originalWidth / originalHeight;
      this.camera.updateProjectionMatrix();
      
      return screenshot;
   }
   
   animate() {
      
      const _this = this;
      // Render loop
      
      requestAnimationFrame( _this.animate );
      
      const mixerUpdateDelta = _this.clock.getDelta();
      
      _this.controls.update();
      
      for(var i=0; i<_this.mixers.length;i++) {
         _this.mixers[i].update( mixerUpdateDelta );
      }
      
      _this.stats.update();
      
      if(_this.pm25) {
         _this.pm25.update();
      }
      
      if(_this.points) {
         _this.points.forEach(p => {
            p.uniforms.sand.wave.value += 0.01;
            if(p.uniforms.sand.wave.value > 2) p.uniforms.sand.wave.value = 1.5 - Math.random()*.2;
         });
      }
      
      TWEEN.update();
      
      _this.composer.render( _this.scene, _this.camera );
      
   }
   
}