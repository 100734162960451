global.$ = require('jquery');

import main3d from './3d/main3d.js';
import bg from './modules/bg.js';
// import ff_web3 from './modules/ff_web3.js';
import ux from './modules/ux.js';
import data from './modules/data.js';
const { detect } = require('detect-browser');

global.browser = detect();
// global.socket = io(window.location.origin,
// {
//   transports: [ "websocket", "polling" ]
// });

// _3d.init();

$(function(){
  console.log('winready');

  if(document.querySelector('#main3d')){
    window._3d = new main3d();
    // window._ffweb3 = new ff_web3();
  } else {
    $('body').addClass('loaded show-ui');
  }

  window._ux = new ux();
  window._data = new data();

  window.loadBg = function(){
    window._bg = new bg();
  }

  $(document).on('keyup', function(e){
    if(e.keyCode==72) {
      $('body').toggleClass('hide-ui');
    }
  });
});