const confetti = require('canvas-confetti');

const texts = {
   'sv' : {
      close: 'Stäng',
      emailNotification: 'Vi behöver din e-post för att meddela när din FolkFigur anländer',
      dataStreamPause: 'Pausa dataströmmen och fånga tillståndet för din FolkFigur',
      dataStreamResume: 'Starta dataströmmen igen. VARNING: du kan förlora din sparade FolkFigur.',
      sign: 'Signera med plånboken & skicka din FolkFigur till distributionskön',
   },
   'en' : {
      close: 'Close',
      emailNotification: 'We use it to notify you about the status of your FolkFigur.',
      dataStreamPause: 'Pause data stream and capture the state for your FolkFigur.',
      dataStreamResume: 'Continue updating data. CAUTION: you might lose the state of your FolkFigur.',
      sign: 'Sign with wallet and submit your FolkFigur to the waitlist.',
   }
}

export default class ux {
   constructor(source) {
      const _this = this;

      window.curLang = 'sv';

      window._noti = function(msg, time = 3000){
         window.clearTimeout(window.notiTimeout);
         $('.noti').remove();
         $('body').append('<div class="noti"><div class="noti--text"></div></div');
         $('.noti--text').html(msg);
         
         setTimeout(function(){
            $('.noti').addClass('in');
         }, 20);
         window.notiTimeout = setTimeout(function(){
            $('.noti').slideUp(200, function(){
               $('.noti').remove();
            });
         }, time);
      }
      window._alert = function(msg){
         $('.alert').remove();
         $('body').append(`<div class="alert"><div class="alert--text"></div><div class="alert--close">${texts[window.curLang].close}</div></div`);
         $('.alert--text').html(msg);
         
         setTimeout(function(){
            $('.alert').addClass('in');
         }, 20);

         $('.alert--close').on('click', function(){
            $('.alert').slideUp(200, function(){
               $('.alert').remove();
            });
         });
      }

      // Individual silly binds

      $('a[href*="#lang"]').on('click', function(e){
         e.preventDefault();

         if($(this).attr('href') === '#lang-swe') {
            $('.lang-eng').hide();
            $('.lang-swe').show();
            $('html').attr('lang', 'sv');
            $('#email').attr('placeholder', 'E-postadress');
            window.curLang = 'sv';
            $('[data-swe]').each(function(){
               $(this).text($(this).attr('data-swe'));
            });
            $('#startmint.resume').text('Avbryt');
            $('#connect.ok').html(`Kopplat: ${window.accShorten}`)
         } else if($(this).attr('href') === '#lang-eng') {
            $('.lang-swe').hide();
            $('.lang-eng').show();
            $('html').attr('lang', 'en');
            $('#email').attr('placeholder', 'Email address');
            window.curLang = 'en';
            $('[data-eng]').each(function(){
               $(this).text($(this).attr('data-eng'));
            });
            $('#startmint.resume').text('Cancel');
            $('#connect.ok').html(`Connected: ${window.accShorten}`)
         }
      });

      $('#main3d, .nav--left img').on('click', function(e){
         if($('body').hasClass('page-open')) {
            $('body').removeClass('page-open journey-open about-open');
         }
     });

      $('a[href="#journey"]').on('click', function(e){
         e.preventDefault();
         $('body').removeClass('about-open faq-open');
         if($('body').hasClass('journey-open')) {
            $('body').removeClass('page-open journey-open');
         } else {
            $('body').addClass('page-open journey-open');
         }
      });

      $('a[href="#about"]').on('click', function(e){
         e.preventDefault();
         $('body').removeClass('journey-open faq-open');
         if($('body').hasClass('about-open')) {
            $('body').removeClass('page-open about-open');
         } else {
            $('body').addClass('page-open about-open');
         }
      });

      $('a[href="#faq"]').off('click').on('click', function(e){
         e.preventDefault();
         $('body').removeClass('journey-open about-open');
         if($('body').hasClass('faq-open')) {
            $('body').removeClass('page-open faq-open');
         } else {
            $('body').addClass('page-open faq-open');
         }
      });

      // $('aside.data').on('click', function(e) {
      //    if($(this).hasClass('paused')) {
      //       window.pauseDataStream = false;
      //       window._data.updateData();
      //       $(this).removeClass('paused');
      //       $('#startmint').removeClass('resume').text('Start minting');
      //       $('#sendmint').removeClass('in');
      //    }
      // }).on('mousemove', function(e){
      //    if($(this).hasClass('paused')) {
      //       $('.tooltip').css({
      //          'right'  : 'auto',
      //          'left'  : e.clientX,
      //          'top'  : e.clientY,
      //       });
      //    }
      // }).on('mouseenter', function(){
      //    if($(this).hasClass('paused')) {
      //       $('.tooltip')
      //       .css('opacity', 1)
      //       .text('Data stream is paused. Click to resume.');
      //    }
      // }).on('mouseleave', function(){
      //    $('.tooltip').css('opacity', 0);
      // });


      // email field
      $('#email').on('input', function(){
         const email = $(this).val();
         console.log(email);

         const valid = email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
         );
         
         if (valid) {
            $('#mintbutton').removeClass('disabled');
            window.email = email;
         } else {
            $('#mintbutton').addClass('disabled');
         }
      }).on('mousemove', function(e){
         $('.tooltip').css({
            'left'  : 'auto',
            'right'  : window.innerWidth - e.clientX,
            'top'  : e.clientY,
         });
      }).on('mouseenter', function(){
         $('.tooltip')
            .css('opacity', 1)
            .text(texts[window.curLang].emailNotification);
      }).on('mouseleave', function(){
         $('.tooltip').css('opacity', 0);
      });


      // minting help
      $('#startmint').on('mousemove', function(e){
         $('.tooltip').css({
            'left'  : 'auto',
            'right'  : window.innerWidth - e.clientX,
            'top'  : e.clientY,
         });
      }).on('mouseenter', function(){
         if(!$(this).hasClass('resume')) {
            $('.tooltip')
            .css('opacity', 1)
            .text(texts[window.curLang].dataStreamPause);
         } else {
            $('.tooltip')
            .css('opacity', 1)
            .text(texts[window.curLang].dataStreamResume);
         }
      }).on('mouseleave', function(){
         $('.tooltip').css('opacity', 0);
      });
      // minting help
      $('#mintbutton').on('mousemove', function(e){
         $('.tooltip').css({
            'left'  : 'auto',
            'right'  : window.innerWidth - e.clientX,
            'top'  : e.clientY,
         });
      }).on('mouseenter', function(){
         if($(this).hasClass('disabled')) {
            // $('.tooltip')
            // .css('opacity', 1)
            // .text('Enter an email address first.');
         } else {
            $('.tooltip')
            .css('opacity', 1)
            .text(texts[window.curLang].sign);
         }
      }).on('mouseleave', function(){
         $('.tooltip').css('opacity', 0);
      });

      // music
      $(window).on('load', function(){
         _this.initAudio();
      });

      $(window).on('click', function(){
         if(!window.audioSynced && window.theAudio) {
            window.audioSynced = true;
            _this.syncAudio();
         }
      });
   }

   syncAudio(){
      window.theAudio = $('#FAKETHIAS audio').get(0);

      var songDuration = window.theAudio.duration / 60;
      var t = new Date();
      var ofHour = ((t.getMinutes() + (t.getSeconds()/60)) / 60)
      // console.log(ofHour);

      var songFitsIntoHour = 60/songDuration; // times

      var currentSongsPlayed = songFitsIntoHour * ofHour;
      var currentPlayHead = currentSongsPlayed % 1;

      window.theAudio.currentTime = window.theAudio.duration * (currentSongsPlayed % 1);

      // window.theAudio.volume = 0.0;
      // window.theAudio.muted = true;
      window.theAudio.play();
   }

   initAudio(){
      if(!$('#FAKETHIAS audio').get(0)) return false;

      window.theAudio = $('#FAKETHIAS audio').get(0);

      var songDuration = window.theAudio.duration / 60;
      var t = new Date();
      var ofHour = ((t.getMinutes() + (t.getSeconds()/60)) / 60)
      // console.log(ofHour);

      var songFitsIntoHour = 60/songDuration; // times

      var currentSongsPlayed = songFitsIntoHour * ofHour;
      var currentPlayHead = currentSongsPlayed % 1;

      window.theAudio.currentTime = window.theAudio.duration * (currentSongsPlayed % 1);

      window.theAudio.volume = 0.0;
      window.theAudio.muted = true;
      window.theAudio.play();

      $(window.theAudio).on('timeupdate', function(e){
         // let time = this.currentTime;
         // let dur = this.duration;
         let curTime = this.currentTime;
         let duration = this.duration;

         let minutes = Math.floor(curTime / 60);
         minutes = (minutes >= 10) ? minutes : "0" + minutes;
         let seconds = Math.floor(curTime % 60);
         seconds = (seconds >= 10) ? seconds : "0" + seconds;

         let durMinutes = Math.floor(duration / 60);
         durMinutes = (durMinutes >= 10) ? durMinutes : "0" + durMinutes;
         let durSeconds = Math.floor(duration % 60);
         durSeconds = (durSeconds >= 10) ? durSeconds : "0" + durSeconds;

         let timeString = minutes + ":" + seconds + " / " + durMinutes + ":" + durSeconds;
         $('.time').text(timeString);
      });

      $('#FAKETHIAS .play').on('click', function(e){
         e.preventDefault();
         if(window.theAudio.muted){
            // window.theAudio.volume = 0;
            window.theAudio.muted = false;
            if(window.audioTween) window.audioTween.stop();
            window.audioTween = new TW.Tween(window.theAudio)
            .to({ volume: 1 }, 2000)
            .easing(TW.Easing.Quadratic.Out)
            .start();
            $(this).addClass('playing');
         } else {
            if(window.window.theAudioTween) window.audioTween.stop();
            window.audioTween = new TW.Tween(window.theAudio)
            .to({ volume: 0 }, 300)
            .easing(TW.Easing.Quadratic.Out)
            .start();
            setTimeout(function(){
               window.theAudio.muted = true;
            }, 500);
            $(this).removeClass('playing');
         }
      });
   }

   createConfetti() {
      var confettiCanvas = document.createElement('canvas');
      confettiCanvas.id = 'confetti';
      confettiCanvas.width = window.innerWidth;
      confettiCanvas.height = window.innerHeight;
      document.body.appendChild(confettiCanvas);
      
      window.theConfetti = confetti.create(confettiCanvas, {
         resize: true,
         useWorker: true
      });

      this.fireConfetti();
   }

   fireConfetti() {
      var count = window.innerWidth < 800 ? 250 : 400;
      var defaults = {
         origin: { y: 0.8 }
      };
      
      function fire(particleRatio, opts) {
         window.theConfetti(Object.assign({}, defaults, opts, {
            particleCount: Math.floor(count * particleRatio)
         }));
      }
      
      fire(0.25, {
         spread: 26,
         startVelocity: 55,
      });
      fire(0.2, {
         spread: 60,
      });
      fire(0.35, {
         spread: 100,
         decay: 0.91,
         scalar: 0.8
      });
      fire(0.1, {
         spread: 120,
         startVelocity: 25,
         decay: 0.92,
         scalar: 1.2
      });
      fire(0.1, {
         spread: 120,
         startVelocity: 45,
      });
      
      setTimeout(function(){
         $('#confetti').remove();
      }, 8000);
   }
}