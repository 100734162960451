export default class pm25 {
   constructor() {
    this.particleNum = 100;
    this.maxRange = 10;
    this.minRange = this.maxRange / 2;

    this.minRangeZ = 10;
    this.minRangeZ = this.minRangeZ / 2;

    this.textureSize = 32.0;
    
    this.velocities = [];
    this.particles = [];

    this.velocityMultiplier = 2.0;

   }

   init(scene) {
    var _this = this;

    /* Snow Particles
    -------------------------------------------------------------*/
    this.geometry = new THREE.BufferGeometry()
    let points = [];
    for (let i = 0; i < this.particleNum; i++) {
        const x = Math.random() * this.maxRange - this.minRange;
        const y = Math.random() * this.minRangeZ - this.minRangeZ;
        const z = Math.random() * this.maxRange - this.minRange;
        const particle = new THREE.Vector3(x, y, z);
        points.push(particle);
        // this.pointGeometry.attributes.position.push(x);
        // this.pointGeometry.attributes.position.push(y);
        // this.pointGeometry.attributes.position.push(z);
        // const color = new THREE.Color(0xffffff);
        // pointGeometry.colors.push(color);
    }

    this.geometry.setFromPoints(points);
    this.geometry.computeVertexNormals();
    
    const pointMaterial = new THREE.PointsMaterial({
        size: 0.08,
        color: 0xFFFFFF,
        vertexColors: false,
        map: _this.getTexture(),
        // blending: THREE.AdditiveBlending,
        // transparent: true,
        opacity: 1.0,
        fog: true,
        depthWrite: true
    });

    for (let i = 0; i < this.particleNum; i++) {
        const x = Math.floor(Math.random() * 6 - 3) * -0.005;
        const y = (Math.floor(Math.random() * 10 + 3) * - 0.005) + 4;
        const z = Math.floor(Math.random() * 10 + 3) * - 0.005;
        // const z = Math.floor(Math.random() * 6 - 3) * 0.1;
        const particle = new THREE.Vector3(x, y, z);
        this.velocities.push(particle);
    }

    this.particles = new THREE.Points(_this.geometry, pointMaterial);
    this.particles.geometry.velocities = this.velocities;
    scene.add(this.particles);
   }

    drawRadialGradation(ctx, canvasRadius, canvasW, canvasH){
        ctx.save();
        const gradient = ctx.createRadialGradient(canvasRadius,canvasRadius,0,canvasRadius,canvasRadius,canvasRadius);
        gradient.addColorStop(0, 'rgba(60, 60, 60, 1.0)');
        gradient.addColorStop(0.5, 'rgba(60, 60, 60, 0.5)');
        gradient.addColorStop(1, 'rgba(60, 60, 60, 0)');
        ctx.fillStyle = gradient;
        ctx.fillRect(0,0,canvasW,canvasH);
        ctx.restore();
    }

    getTexture(){
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
    
        const diameter = this.textureSize;
        canvas.width = diameter;
        canvas.height = diameter;
        const canvasRadius = diameter / 2;
    
        /* gradation circle
        ------------------------ */
        this.drawRadialGradation(ctx, canvasRadius, canvas.width, canvas.height);
        
        const texture = new THREE.Texture(canvas);
        texture.type = THREE.FloatType;
        texture.needsUpdate = true;
        return texture;
    }

    update() {
        // orbitControls.update();
        
        var pos = this.particles.geometry.attributes.position;
        var posArr = pos.array;
        var velArr = this.particles.geometry.velocities;

        // for(var i = 0; i < posArr.length/3; i++) 
        
        for(var i = 0; i < posArr.length; i+=3){
        // posArr.forEach((vertex, i) => {
            const velocity = velArr[i/3];
            
            const velX = Math.sin(_3d.clock.elapsedTime/1000 * 0.001 * velocity.x) * 0.1;
            const velZ = Math.cos(_3d.clock.elapsedTime/1000 * 0.0015 * velocity.z) * 0.001;
            
            posArr[i] += velX;
            // posArr[i+1] += velZ;
            // posArr[i+2] += velocity.y;
            posArr[i+2] += velocity.z * this.velocityMultiplier;
            
            if (posArr[i+2] < -this.minRangeZ ) {
                posArr[i+2] = this.minRangeZ;
                posArr[i] = Math.random() * this.maxRange - this.minRange;
                posArr[i+1] = Math.random() * this.maxRange - this.minRange + 4;
            }            
        }
        // _3d.pm25.particles.geometry.attributes.position
        this.particles.geometry.attributes.position.needsUpdate = true;
    }
}
