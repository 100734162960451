export default class bg {
   constructor(source) {

      const _this = this;

      _this.refresh();
      _this.setContrast();
      
      window.bgRefresh = setInterval(function(){
         _this.refresh();
      }, 30000);
   }
   
   setContrast(){
      const d = new Date();
      let hours = d.getUTCHours()+1;
      if(hours > 18 || hours < 6) {
         if(hours > 18) hours = 24-hours; 
         
         const minutes = d.getUTCMinutes()/60;
         
         const contrast = 0.3333 * (hours+minutes) + 1;
         $('#main3d .bg').css('filter',`contrast(${contrast}) saturate(0.6)`)
      }
   }

   refresh(){
      // console.log('Got new background');
      $.get('/dist/cams.json',{},function(content){
         let places = content.places;

         // var startDate = 1667775601000; // 7.11.2022 kl. 00:00:01 GMT+01:00
         // var startDate = 1669791600000; // 30.11.2022 kl. 08:00:00 GMT+01:00
         // var startDate = 1692921600000; // 25.8.2023 kl. 08:00:00 GMT+01:00
         var startDate = 1694757600000; // Friday 15 September 2023 06:00:00 GMT
         // var startDate = 1713792364000; // Monday 22 April 2024 13:26:04

         var dateNow = Date.now();
         var days = Math.floor(((Math.abs(dateNow - startDate)) / 1000) / 86400);

         if(days < places.length) {
            
            var currentPlace = places[Math.floor(days)];
            var currentPlaceUrl = places[Math.floor(days)].url;
            // console.log(currentPlace);
            
            if(window.place !== currentPlace.name){
               let d = new Date();
               let day = d.getDate();
               let month = parseInt(d.getMonth())+1;
               let year = d.getFullYear().toString().substring(2);
               
               window._noti(`${currentPlace.name}<br>${day}.${month}.${year}`);
            }
            window.place = currentPlace.name;
            
            // var currentPlace = lines[Math.floor(days)];
            
            var loadedImage = `${currentPlaceUrl}${currentPlaceUrl.indexOf('?')<0 ? '?' : '&' }v=${dateNow}`;
            // _img.src = this.src;
            let curBg = $('.bg--img').css('background-image');
            
            $('.bg--img').css({
               'background-image' : `${curBg}, url(${loadedImage})`
            });
            
            setTimeout(function(){
               $('.bg').addClass('in');
            }, 100);
            
            setTimeout(function(){
               $('.bg--img').css({
                  'background-image' : `url(${loadedImage})`
               });
            }, 500);
         } else {
            window.clearInterval(window.bgRefresh);
         }
      });
   }
}