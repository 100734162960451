const axios = require('axios').default;

export default class data {
   constructor(source) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      var seeID = urlParams.get('id');

      if(!seeID){
         this.updateData();
      } else {
         window.seeID = seeID;
         var url = window.location.href;
         url = url.replaceAll('?id=','/');
         history.replaceState({}, '', url);
         this.getData(seeID);
      }
   }

   async getData(seeID) {
      const _this = this;

      const getSee = await axios({
         method: "post",
         url: `https://api42.folkfigur.se/v1/see/${seeID}`,
         // data: formData,
      }).then(function(res) {
         if(res.status==200) {
            const metaUrl = `https://folkfigur.mypinata.cloud/ipfs/${seeID}`;
            $('meta[property="og:image"]').attr('content', metaUrl);

            if(res.data && !res.data.error) {
               const data = res.data;

               window.dataStream = data;
               
               _this.renderData();

               setTimeout(function(){
                  $('body').addClass('loaded viewpage');
                  _this.renderData();
               }, 1000);
               
               $(window).on('load', function(){
                  _this.renderData();
               });
            } else {
               $('body').addClass('loaded');
               window._noti(`Ingen data hittades`, 2400);
               window.clearTimeout(window.showUiTimeout);

               setTimeout(function(){ 
                  window._noti(`Vi tar dig hem i 5`, 10000);

                  setTimeout(function(){ 
                     $('.noti--text').html(`Vi tar dig hem i 4`); 
                  
                     setTimeout(function(){ 
                        $('.noti--text').html(`Vi tar dig hem i 3`); 
                  
                        setTimeout(function(){ 
                           $('.noti--text').html(`Vi tar dig hem i 2`); 
                  
                           setTimeout(function(){ 
                              $('.noti--text').html(`Vi tar dig hem i 1`); 

                              setTimeout(function(){ 
                                 $('.noti--text').html(`Vi tar dig hem i 0`); 
                                 document.querySelector('body').classList.add('out');
                                 setTimeout(function(){
                                    window.location.href = '/';
                                 }, 1100);
                              }, 1000);
                           }, 1000);
                        }, 1000);
                     }, 1000);
                  }, 1000);
               }, 3000);
            }
         }
      });
   }

   updateData() {
      const _this = this;
      
      window.clearTimeout(window.updateDataTimeout);
      
      if(!window.pauseDataStream) {
         window.prevDataStream = window.dataStream ? window.dataStream : false;
         
         if(window._bg) window._bg.setContrast();
         
         $.get('https://api42.folkfigur.se/v1/dada', function(data){
         window.dataStream = data;
         _this.renderData();
         
         window.updateDataTimeout = setTimeout(function(){
            _this.updateData();
         }, 1000);
      });
   }
   }

   renderData() {
      if(!window.dataStream) return false;

      $('[data-id="water"]').html(window.dataStream.water);
      $('[data-id="air"]').html(window.dataStream.air);
      $('[data-id="pm25"]').html(window.dataStream.pm25);
      $('[data-id="song"]').html(`${window.dataStream.listens.song} [${window.dataStream.listens.int}]`);
      $('[data-id="menu"]').html(`${window.dataStream.eats.menu.replaceAll('<br/>',' ')} [${window.dataStream.eats.int}]`);
      $('[data-id="event"]').html(`${window.dataStream.surprise.event} [${window.dataStream.surprise.int}]`);
      $('[data-id="train"]').html(`${window.dataStream.train.operator} [${window.dataStream.train.int}]`);
      $('[data-id="elec"]').html(window.dataStream.elec);
      
      if(window._3d) {
         if(!window.prevDataStream || (window.dataStream.surprise.int !== window.prevDataStream.surprise.int)) {
            window._3d.changeColouring(window.dataStream.surprise.int);
         }
         
         if(!window.prevDataStream || (window.dataStream.listens.song !== window.prevDataStream.listens.song)) {
            window._3d.changeSong(window.dataStream.listens.int);
         }
         
         window._3d.changeLights(window.dataStream.surprise.int, window.dataStream.train.int);
         
         let elecPresumedMax = 20000;
         let elecPresumedMin = 11000;
         let elecValue = Math.max(elecPresumedMin, Math.min(parseInt(window.dataStream.elec), elecPresumedMax));
         let elecPrc = (elecValue-elecPresumedMin) / (elecPresumedMax-elecPresumedMin);
         
         let newBloomRadius = 8 + ((parseFloat(parseInt(window.dataStream.elec)) * 0.0011) / 3);
         let newBloomStrength = 0.18 + (0.12 * elecPrc);
         let newBloomThreshold = Math.max( 0.3 - (0.3 * elecPrc),   0.0);
         
         new TW.Tween(_3d.bloomPass)
         .to({
            radius: newBloomRadius,
            strength: newBloomStrength,
         }, 150)
         .easing(TW.Easing.Quadratic.In)
         .start();
         
         if(window._3d.pm25) {
            _3d.pm25.velocityMultiplier = 3 + (parseFloat(window.dataStream.pm25));
         }
      }
   }
}